import DocumentationPage from "@components/DocumentationPage";
import WebpImage from "@components/WebpImage";

export default () => 
    <DocumentationPage>
        <h1>With blurhash</h1>
        <WebpImage 
            size={300}
            delay={1000}
            blurhash="LnIhNvVYbapIyZR%s.flb{f+j?s."
            src="https://profiles-media.reshopper.com/2020/07/31/2dd32e15-23f8-41ad-8118-141658d1ab62/1000x1333.webp" 
        />

        <h1>With loading SRC</h1>
        <WebpImage 
            size={300}
            delay={1000}
            loadingSrc="https://profiles-media.reshopper.com/2020/07/31/2dd32e15-23f8-41ad-8118-141658d1ab62/256x256.webp"
            src="https://profiles-media.reshopper.com/2020/07/31/2dd32e15-23f8-41ad-8118-141658d1ab62/1000x1333.webp" />

        <h1>Without loading SRC</h1>
        <WebpImage 
            size={300}
            delay={1000}
            src="https://profiles-media.reshopper.com/2020/07/31/2dd32e15-23f8-41ad-8118-141658d1ab62/1000x1333.webp" />

        <h1>Without loading SRC (invalid)</h1>
        <WebpImage 
            size={300}
            delay={1000}
            src="https://profiles-media.reshopper.com/failure" />
    </DocumentationPage>